<template>
    <div id='full-page'>
        <global-nav ></global-nav>
        <router-view v-slot="{ Component }">
            <transition>
            <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script>
import globalNav from '@/components/GlobalNav.vue'
export default {
  components: {
    globalNav
  },
  name: 'AuthContainer'
}
</script>

<style lang="scss">
    #full-page{
        // background-color: var(--background-light);
        min-height: 100vh;
    }
    .form-control{
       border-radius: rgba(0,0,0,.54);
        border-radius: 10px;
        padding: 12px
    }
</style>
