<template>
    <nav class="navbar navbar-expand-md navbar-light">
        <div class="container-md">
              <a class="navbar-brand ms-1 d-inline-flex align-items-center" href="/">
                <img src="/img/branding/skipiste_logo_clean.svg" alt="" width="40">
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidenav" aria-controls="sidenav" aria-expanded="false" aria-label="Toon navigatie">
                <font-awesome-icon :icon="['fad', 'bars']" />
              </button>
              <div class="collapse navbar-collapse" id="sidenav">
                <!-- <ul class="navbar-nav mx-auto mb-2 mb-lg-0" id="centernav"> -->
                  <!-- </ul> -->
                <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
                  <!-- hide route if active -->
                  <li class="nav-item">
                      <router-link  :to="{ name: 'register' }" custom v-slot="{ navigate, href, route, isActive }">
                        <a :href="href"  class="nav-link btn btn-light mb-2 mb-md-0" :class="[isActive && 'active btn-primary']" @click="navigate">{{ route.meta.title }}</a>
                      </router-link>
                  </li>
                 <li class="nav-item">
                      <router-link  :to="{ name: 'login' }" custom v-slot="{ navigate, href, route, isActive }">
                        <a :href="href" class="nav-link btn btn-light ms-0 ms-md-2" :class="[isActive && 'active btn-primary']" @click="navigate">{{ route.meta.title }}</a>
                      </router-link>
                  </li>
                </ul>
              </div>
          </div>
    </nav>
</template>

<script>
export default {
  name: 'globalNav'

}
</script>

<style lang="scss" scoped>

  .nav-link.btn-light{
    font-weight: 500;
    transition: all 0.5s ease-in-out;
  }

  .btn-primary.active{
    color: #fff !important;
  }

  .navbar{
    background: none !important;
    border: none;
  }

  #centernav{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
  }

  @media screen and (max-width: 992px){
      #centernav{
          position: initial;
          left: 0;
          top: 0;
          transform: none;
      }
  }
  .navbar-toggler{
  border: none;
}

.navbar-toggler svg{
  color: var(--sp-darker);
}

</style>
